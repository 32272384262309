import Vue from "vue/dist/vue.js";
import axios from "axios";
import Masonry from "masonry-layout";
import imagesLoaded from "imagesloaded";
import Spotlight from "spotlight.js";

const portafolioInterior = () => {
	// query url params
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const query = urlParams.get("p");

	// Carga proyectos portafolio
	new Vue({
		el: "#portafolioItem",
		data() {
			return {
				items: null,
				loading: true,
			};
		},
		mounted() {
			axios({
				url: "https://r59jt0f3.api.sanity.io/v1/graphql/production/default",
				method: "post",
				data: {
					query: `
					query getPortafolioBySlug($slug: String = "${query}") {
						allProyectos(where: { slug: { current: { eq: $slug } } }) {
						  banner {
							asset {
							  url
							}
						  }
						  bannerTxt
						  titulo
						  slug {
							current
						  }
						  aplicacion
						  tipo
						  acabado
						  diseno
						  constructora
						  galeria {
							image {
							  asset {
								url
							  }
							}
							acomodo
						  }
						}
					  }
					`,
				},
			})
				.then((response) => {
					// console.log(response.data.data.allProyectos[0]);
					this.items = response.data.data.allProyectos;
					this.loading = false;

					setTimeout(() => {
						const msnry = new Masonry(
							".portafolio-interior-grid .grid",
							{
								itemSelector: ".item",
								percentPosition: true,
								columnWidth: ".sizer",
							}
						);

						imagesLoaded(
							"progress",
							document.querySelector(
								".portafolio-interior-grid .grid"
							),
							function (instance) {
								msnry.layout();
							}
						);
					}, 500);
				})
				.catch((error) => {
					console.log(error);
					this.errored = true;
				});
		},
	});
};

export default portafolioInterior;

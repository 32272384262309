import Vue from "vue/dist/vue.js";
import axios from "axios";
import Masonry from "masonry-layout";
import imagesLoaded from "imagesloaded";
import Spotlight from "spotlight.js";

const galeriaDart = () => {
	// query url params
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const query = urlParams.get("i");

	// Carga proyectos portafolio
	new Vue({
		el: "#galeria",
		data() {
			return {
				items: null,
				loading: true,
			};
		},
		mounted() {
			axios({
				url: "https://r59jt0f3.api.sanity.io/v1/graphql/production/default",
				method: "post",
				data: {
					query: `query getGaleriaDartBySlug($slug: String = "${query}") {
                        allGaleriaDart(where: { slug: { current: { eq: $slug } } }) {
                          title
                          slug {
                            current
                          }
                          description
                          galeria {
                            image {
                              asset {
                                url
                              }
                            }
                            gTitle
                            gSize
                            gRecubrimiento
                            acomodo
                          }
                        }
                      }`,
				},
			})
				.then((response) => {
					// console.log(response.data.data.allGaleriaDart[0]);
					this.items = response.data.data.allGaleriaDart[0];
					this.loading = false;

					setTimeout(() => {
						const msnry = new Masonry(".grid", {
							itemSelector: ".item",
							percentPosition: true,
							columnWidth: ".sizer",
						});

						imagesLoaded(
							"progress",
							document.querySelector(".grid"),
							function (instance) {
								msnry.layout();
							}
						);
					}, 500);
				})
				.catch((error) => {
					console.log(error);
					this.errored = true;
				});
		},
	});
};

export default galeriaDart;

import { gsap } from "gsap";
import Splitting from "splitting";
import Headroom from "headroom.js";

const menu = () => {
	// Animación main-header
	var myElement = document.querySelector(".main-header");
	var headroom = new Headroom(myElement, {
		tolerance: 20,
		classes: {
			pinned: "headroom--pinned",
			unpinned: "headroom--unpinned",
		},
	});
	headroom.init();

	// menu overlay
	const menuOverlay = document.querySelector(".menu-overlay"),
		menuOverlayBack = document.querySelector(".menu-overlay .back"),
		menuOverlayPic = document.querySelector(".menu-overlay .pic"),
		menuOverlayOpen = document.querySelector(
			".main-header .btn-burger button"
		),
		menuOverlayClose = document.querySelector(".menu-overlay .close"),
		menuOverlayLista = document.querySelectorAll(
			".menu-overlay .menu ul li"
		),
		menuOverlayInfo = document.querySelectorAll(".menu-overlay .info"),
		menuOverlayLogo = document.querySelectorAll(".menu-overlay .logo");

	// set estado inicial del menu
	gsap.set(menuOverlay, { css: { visibility: "hidden" } });
	gsap.set(menuOverlayBack, { height: "0" });
	gsap.set(menuOverlayPic, { left: "-100%" });

	// timeline
	const menuTimeline = gsap.timeline({ paused: true });

	menuTimeline
		.set(menuOverlay, { css: { visibility: "visible" } })
		.to(menuOverlayPic, { left: "0", ease: "power3.out", duration: 1 })
		.to(
			menuOverlayBack,
			{ height: "100%", ease: "power5.out", duration: 1 },
			"<"
		)
		.from(
			menuOverlayLogo,
			{
				x: -50,
				opacity: 0,
				ease: "power3.out",
				duration: 1,
			},
			"<"
		)
		.from(
			menuOverlayLista,
			{
				x: -50,
				opacity: 0,
				ease: "power3.out",
				duration: 1,
				stagger: 0.1,
			},
			"<"
		)
		.from(
			menuOverlayInfo,
			{
				x: 50,
				opacity: 0,
				ease: "power3.out",
				duration: 1,
			},
			"-=1"
		);

	// Abrir menu
	menuOverlayOpen.addEventListener("click", (e) => {
		e.preventDefault();
		menuTimeline.timeScale(1).play();
	});

	// Cerrar menu
	menuOverlayClose.addEventListener("click", (e) => {
		e.preventDefault();
		menuTimeline.timeScale(2.5).reverse();
	});

	// Cerrar menu al hacer click en links
	const navLinks = document.querySelectorAll(".menu-overlay .menu ul li a");
	navLinks.forEach((link) => {
		link.addEventListener("click", () => {
			menuTimeline.timeScale(2).reverse();
		});
	});

	// Effecto  al hacer hover el nav links
	Splitting();
};

export default menu;

import AOS from "aos";
import { gsap } from "gsap";
import barba from "@barba/core";
import barbaPrefetch from "@barba/prefetch";

import menu from "./menu";
import cambioTemplate from "./cambioTemplate";
import formaValidar from "./formaValidar";
import formaCotiza from "./formaCotiza";
import home from "./home";
import portafolio from "./portafolio";
import portafolioInterior from "./portafolioInterior";
import dline from "./dline";
import galeriaDline from "./galeriaDline";
import dart from "./dart";
import galeriaDart from "./galeriaDart";
import stahl from "./stahl";
import galeriaStahl from "./galeriaStahl";

/*
-----------------------
Animaciones fade scroll
-----------------------
*/
AOS.init();

let scrollRef = 0;
window.addEventListener("scroll", function () {
	// increase value up to 10, then refresh AOS
	if (scrollRef <= 10) {
		scrollRef++;
	} else {
		AOS.refresh();
	}
});

/*
-----------------------
Menu
-----------------------
*/
menu();

/*
-----------------------
Barba js
-----------------------
*/
const animationEnter = (container) => {
	return gsap.from(container, {
		autoAlpha: 0,
		duration: 1,
		clearProps: "all",
		ease: "none",
	});
};

const animationLeave = (container) => {
	return gsap.to(container, {
		autoAlpha: 0,
		duration: 1,
		clearProps: "all",
		ease: "none",
	});
};

// Pre-carga siguiente página
barba.use(barbaPrefetch);

// Hooks al entrar a la pagina
barba.hooks.once(() => {
	formaValidar("formaFooter");
});

barba.init({
	// debug: true,
	views: [
		{
			namespace: "home",
			beforeEnter() {
				cambioTemplate();
				home();
			},
		},
		{
			namespace: "dline",
			beforeEnter() {
				cambioTemplate();
				dline();
			},
		},
		{
			namespace: "galeria-dline",
			beforeEnter() {
				cambioTemplate();
				galeriaDline();
			},
		},
		{
			namespace: "dart",
			beforeEnter() {
				cambioTemplate();
				dart();
			},
		},
		{
			namespace: "galeria-dart",
			beforeEnter() {
				cambioTemplate();
				galeriaDart();
			},
		},
		{
			namespace: "stahl-studio",
			beforeEnter() {
				cambioTemplate("stahl");
				stahl();
			},
		},
		{
			namespace: "galeria-stahl",
			beforeEnter() {
				cambioTemplate("stahl");
				galeriaStahl();
			},
		},
		{
			namespace: "portafolio",
			beforeEnter() {
				cambioTemplate();
				portafolio();
			},
		},
		{
			namespace: "portafolio-interior",
			beforeEnter() {
				cambioTemplate();
				portafolioInterior();
			},
		},
		{
			namespace: "cotiza",
			beforeEnter() {
				cambioTemplate();
				formaCotiza("formaCotiza");
			},
		},
		{
			namespace: "nosotros",
			beforeEnter() {
				cambioTemplate();
			},
		},
		{
			namespace: "aviso",
			beforeEnter() {
				cambioTemplate();
			},
		},
		{
			namespace: "gracias",
			beforeEnter() {
				cambioTemplate();
			},
		},
	],
	transitions: [
		{
			once({ next }) {
				animationEnter(next.container);
			},
			leave: ({ current }) => {
				// console.log("saliendo");
				animationLeave(current.container);
			},
			enter({ next }) {
				// console.log("entrando");
				animationEnter(next.container);
			},
		},
	],
});

if ("scrollRestoration" in history) {
	history.scrollRestoration = "manual";
}

// cambio header y footer
export default function cambioTemplate(seccion) {
	// const logoCont = document.querySelector(".main-header .logo .logo-cont");
	const logoHeaderSide = document.querySelector(".main-header");
	const footerCont = document.querySelector(".main-footer");
	if (seccion === "stahl") {
		// logoCont.classList.add("logo-s");
		footerCont.classList.add("stahl");
		logoHeaderSide.classList.add("stahl-header");
		logoHeaderSide.classList.remove("dline-header");
	} else {
		// logoCont.classList.remove("logo-s");
		footerCont.classList.remove("stahl");
		logoHeaderSide.classList.add("dline-header");
		logoHeaderSide.classList.remove("stahl-header");
	}

	// scroll hacia arriba al cambiar la página
	const toTop = window.scrollY;
	if (toTop > 0) {
		window.scrollTo(0, 0);
	}
	setTimeout(() => {
		if (toTop > 0) {
			window.scrollTo(0, 0);
		}
	}, 100);
}

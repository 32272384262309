import barba from "@barba/core";
import jQuery from "jquery";
window.$ = window.jQuery = jQuery;

import Swal from "sweetalert2/dist/sweetalert2.js";

import validate from "jquery-validation";

// import jCaptcha from "./captcha.min.js";

export default function formaCotiza(id) {

	

	$(function () {

		$("#" + id).validate({
			errorElement: "small",
			errorClass: "text-danger",
			rules: {
				nombre: "required",
				email: {
					required: true,
					email: true,
				},
				tel: "required",
				mensaje: "required",
			},
			messages: {
				nombre: "Este campo es requerido",
				email: {
					required: "Ingresa un correo electrónico ",
					email: "Tu dirección de correo electrónico no es válida",
				},
				tel: "Este campo es requerido",
				mensaje: "Este campo es requerido",
			},
			submitHandler: function (currentForm) {
				var route = "./assets/php/process-cotiza.php";
				var data = new FormData(currentForm);
				var button = $(currentForm).find(":submit");
				var buttonTxt = button.html();

				var request = $.ajax({
					url: route,
					method: "POST",
					data: data,
					dataType: "json",
					contentType: false,
					processData: false,
					cache: false,
					beforeSend: function () {
						button.html("<span>Enviando</span>");
					},
				});

				request.done(function (data) {
					console.log("json ", data);
					button.html(buttonTxt);

					if (data.status_code == 200) {
						$(currentForm)[0].reset();
						grecaptcha.reset(widgetId2);

						// Redireccionar a gracias
						// window.location.href = "gracias.html";
						barba.go("gracias.html");
					} else {
						Swal.fire({
							icon: "warning",
							// title: data.message,
							html: "<h5>" + data.message + "</h5>",
							showConfirmButton: true,
							confirmButtonText: "Cerrar",
							customClass: {
								confirmButton: "btn btn-pill",
								cancelButton: "btn btn-danger",
							},
							confirmButtonColor: "#FCB124",
						});
					}
				});

				request.fail(function (jqXHR, textStatus) {
					console.log("Request failed: " + textStatus);
					// grecaptcha.reset();
					button.html(buttonTxt);
					Swal.fire({
						icon: "error",
						// title: data.message,
						html: "<p>" + data.message + "</p>",
						showConfirmButton: true,
						confirmButtonText: "Cerrar",
						customClass: {
							confirmButton: "btn btn-pill",
							cancelButton: "btn btn-danger",
						},
						confirmButtonColor: "#FCB124",
					});
				});
			},
		});
	});
}

import Vue from "vue/dist/vue.js";
import axios from "axios";
import Masonry from "masonry-layout";
import imagesLoaded from "imagesloaded";
import Spotlight from "spotlight.js";

const portafolio = () => {
	new Vue({
		el: "#portafolio",
		data() {
			return {
				items: null,
				cover: null,
				loading: true,
			};
		},
		mounted() {
			// banner
			axios({
				url: "https://r59jt0f3.api.sanity.io/v1/graphql/production/default",
				method: "post",
				data: {
					query: `query getPortafolioBySlug($slug: String = "portafolio-de-proyectos") {
						allPaginas(where: { slug: { current: { eq: $slug } } }) {
						  slug{
							current
						  }
						  banner{
							asset{
							  url
							}
						  }
						  tBanner
						  title
						  decription
						}
					  }
					  `,
				},
			})
				.then((response) => {
					// console.log(response.data.data.allPaginas[0]);
					this.cover = response.data.data.allPaginas[0];
					this.loading = false;
				})
				.catch((error) => {
					console.log(error);
					this.errored = true;
				});

			// portafolio
			axios({
				url: "https://r59jt0f3.api.sanity.io/v1/graphql/production/default",
				method: "post",
				data: {
					query: `{
						allProyectos(sort: { titulo: ASC }) {
						  _id
						  titulo
						  bannerTxt
						  slug {
							current
						  }
						  thumbnail {
							image {
							  asset {
								url
							  }
							}
							acomodo
						  }
						  tipo
						}
					  }`,
				},
			})
				.then((response) => {
					// console.log(response.data.data.allProyectos);
					this.items = response.data.data.allProyectos;

					setTimeout(() => {
						const msnry = new Masonry(".grid", {
							itemSelector: ".item",
							percentPosition: true,
							columnWidth: ".sizer",
						});

						imagesLoaded(
							"progress",
							document.querySelector(".grid"),
							function (instance) {
								msnry.layout();
								// console.log(instance);
							}
						);
					}, 500);
				})
				.catch((error) => {
					console.log(error);
					this.errored = true;
				});
		},
	});
};

export default portafolio;

import Vue from "vue/dist/vue.js";
import axios from "axios";

const dline = () => {
	new Vue({
		el: "#dline",
		data() {
			return {
				items: null,
				cover: null,
				loading: true,
			};
		},
		mounted() {
			// cover
			axios({
				url: "https://r59jt0f3.api.sanity.io/v1/graphql/production/default",
				method: "post",
				data: {
					query: `query getPortafolioBySlug($slug: String = "d-line") {
						allPaginas(where: { slug: { current: { eq: $slug } } }) {
						  slug {
							current
						  }
						  banner {
							asset {
							  url
							}
						  }
						  tBanner
						  title
						  decription
						}
					  }`,
				},
			})
				.then((response) => {
					// console.log(response.data.data.allPaginas[0]);
					this.cover = response.data.data.allPaginas[0];
					this.loading = false;
				})
				.catch((error) => {
					console.log(error);
					this.errored = true;
				});

			// items
			axios({
				url: "https://r59jt0f3.api.sanity.io/v1/graphql/production/default",
				method: "post",
				data: {
					query: `{
						allGaleriaDline(sort: { title: ASC }, limit: 2) {
						  title
						  thumbnail {
							asset {
							  url
							}
						  }
						  slug {
							current
						  }
						  description
						}
					  }`,
				},
			})
				.then((response) => {
					// console.log(response.data.data.allGaleriaDline);
					this.items = response.data.data.allGaleriaDline;
				})
				.catch((error) => {
					console.log(error);
					this.errored = true;
				});
		},
	});
};

export default dline;

import Vue from "vue/dist/vue.js";
import axios from "axios";
import imagesLoaded from "imagesloaded";
import gsap from "gsap/gsap-core";

const home = () => {
	new Vue({
		el: "#home",
		data() {
			return {
				items: null,
				loading: true,
			};
		},
		mounted() {
			// Banner
			axios({
				url: "https://r59jt0f3.api.sanity.io/v1/graphql/production/default",
				method: "post",
				data: {
					query: `{
						allBannerHome {
						  title
						  image {
							asset {
							  url
							}
						  }
						}
					  }`,
				},
			})
				.then((response) => {
					// console.log(response.data.data.allBannerHome[0]);
					this.items = response.data.data.allBannerHome[0];
					this.loading = false;

					imagesLoaded(
						"done",
						document.querySelector(".poster img"),
						function (instance) {
							// console.log(instance);
							const intro = gsap.timeline({ paused: true });
							intro
								.to(".main-banner .poster img", {
									opacity: 1,
									duration: 5,
								})
								.from(
									".main-banner .top",
									{
										y: "-100%",
										ease: "power3.out",
										delay: 0.5,
										duration: 3.5,
									},
									"<"
								)
								.from(
									".main-banner .bottom",
									{
										y: "100%",
										ease: "power3.out",
										delay: 0.5,
										duration: 3.5,
									},
									"<"
								);

							intro.play();
						}
					);
				})
				.catch((error) => {
					console.log(error);
					this.errored = true;
				});
		},
	});
};

export default home;
